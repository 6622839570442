import React from 'react';

import sketch1 from '../images/sketches/ski.png';
import sketch2 from '../images/sketches/sport.png';
import sketch3 from '../images/sketches/weight.png';
import sketch4 from '../images/sketches/brainstorm.png';
import sketch5 from '../images/sketches/music.png';
import sketch6 from '../images/sketches/puzzle.png';

import resume from '../files/Resume.pdf';
import readme from '../files/README.pdf';
import skills from '../files/Skills.pdf';
import coverletter from '../files/Cover Letter.pdf';
import testimonials from '../files/Testimonials.pdf';

import '../styles/corkboard.css';
import '../styles/corkboard-anims.css';

function CorkBoard({setOpenFile}) {
    const sketches = [sketch1, sketch2, sketch3, sketch4, sketch5, sketch6];
  
    return (
      <div className="corkborder">
        <div className="corkboard row">
          <Paper color="red" title="README" number={1} onClick={() => setOpenFile(readme)}/>
          <Paper title="Resume" number={2} onClick={() => setOpenFile(resume)}/>
          <Paper title="Cover Letter" number={3} onClick={() => setOpenFile(coverletter)}/>
          <Paper title="Skills" number={4} onClick={() => setOpenFile(skills)}/>
          <Paper title="Testimonials" number={5} onClick={() => setOpenFile(testimonials)}/>
  
          <div className="row" style={{height: '83%', width: 'auto'}}>
            <div className= "postits" style={{marginLeft: '2vw'}}>
              <PostIt sketch={0} s={sketches}/>
              <PostIt sketch={1} s={sketches}/>
              <PostIt sketch={2} s={sketches}/>
            </div>
            <div className= "postits">
              <PostIt sketch={3} s={sketches}/>
              <PostIt sketch={4} s={sketches}/>
              <PostIt sketch={5} s={sketches}/>
            </div>
          </div>
        </div>
      </div>
    );
}
  
function Paper({color, title, number, onClick}) {
    color = (color==="red") ? "#8C0E0E" : "#2D2D2D";
  
    let paperDelay = {
      animationDelay: `${number/2}s`
    }
    let pinDelay = {
      animationDelay: `${.18+number/2}s`
    }
  
    return (
      <div className="paper-wrapper" onClick={onClick}>
        <div className="pin" style={pinDelay}>
          <div className="pin-base" style={{backgroundColor: color}}/>
          <div className="pin-head" style={{backgroundColor: color}}/>
        </div>
        <div className="paper col" style={paperDelay}>
          <p className="paper-title" style={{color: color}}>
            {title}
          </p>
          <p className="paper-text" style={{color: color}}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>
      </div>
    );
}

function PostIt({sketch, s}){
    return(
      <span className="postit-wrap">
        <div className="postit center">
          <img src={s[sketch]} className='postit-image'/>
        </div>
      </span>
    )
}
  
export default CorkBoard;